.modal-content {
	// background: rgba(0, 0, 0, 0.5);
	background: lightgrey;
	padding: 20px;

	input {
		// background: rgba(254, 254, 254, 0.1);
		background: rgba(0, 0, 0, 0.7);
		color: white;
		padding: 10px 10px;
	}

	button {
		font-weight: bold;
		padding: 10px 10px;
		background: black;
		color: white;
	}
}
