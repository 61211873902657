.no-drag {
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.blur {
	pointer-events: none;
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-o-filter: blur(3px);
	-ms-filter: blur(3px);
	filter: blur(3px);
}

.text-shadow {
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 12px rgba(0, 0, 0, 0.3);
}

.gradient {
	-webkit-mask-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 0)
	);
	-webkit-mask-size: 100%;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: left top, left bottom;
}

.gradient-bottom {
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 1),
		rgba(0, 0, 0, 0)
	);
	-webkit-mask-size: 100%;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: left top, left bottom;
}
